<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		class="accordion__item"
		:class="{ 'accordion__item--highlight': isHighlighted, 'accordion__item--open': isOpen }"
	>
		<button
			type="button"
			class="accordion__item-summary"
			@click="toggle()"
		>
			<h3 class="unset-h3">{{ model.question }}</h3>
			<smart-svg
				:url="IconSystemChevronDown"
				:width="100"
				:height="100"
			/>
		</button>
		<transition name="accordion">
			<div
				v-show="isOpen"
				class="accordion__item-expandable"
			>
				<div class="accordion__item-content">
					<div
						class="rich-text"
						v-html="model.answer"
					></div>
					<div
						v-if="usabilla?.showUsabilla"
						class="ab-accordion__usabilla"
						:ub-in-page="usabilla?.widgetId"
					></div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script setup lang="ts">
	import { computed, ref, watch } from 'vue';
	import { IAccordionItemBlock } from '@/types/block.js';
	import IconSystemChevronDown from '@/assets/svg/icon_system_Chevron_down.svg';
	import { dataLayer } from '@/components/utilities/trackingUtility';

	interface usabillaData {
		showUsabilla?: boolean;
		scriptId?: string;
		widgetId?: string;
	}

	const props = defineProps<{
		model: IAccordionItemBlock;
		usabilla?: usabillaData;
	}>();

	const isOpen = ref(false);
	const isViewed = ref(false);

	const isHighlighted = computed(() => {
		return props.model.isNew && !isViewed.value;
	});

	const toggle = () => {
		if (!isViewed.value) {
			isViewed.value = true;
		}
		isOpen.value = !isOpen.value;
	};

	const trackAccordionItem = () => {
		dataLayer.push({
			event: 'trackNavigation',
			navigationAction: isOpen.value ? 'accordion open' : 'accordion close',
			navigationType: 'accordion',
			navigationDestination: props.model.question,
		});
	};

	watch(
		() => isOpen.value,
		() => {
			if (dataLayer) {
				trackAccordionItem();
			}
		}
	);
</script>

<style lang="scss">
	@import './AccordionItemBlock.module.scss';
</style>
